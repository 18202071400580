.response-item {

    color: #fff;
    background-color: #0099ff;

    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    padding: 8px 13px;
    margin: 7px 10px;
    border-radius: 17px;
}