.popup-background {

    position: absolute;
    display: flex;
    z-index: 1000;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.80);
}

.dialog-popup-background {

    position: absolute;
    display: flex;
    z-index: 1010;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.80);
}

.popup-container {

    align-self: center;
    width: 400px;
    background-color: #f0f0f0;
    border-radius: 15px;
}

.popup-title-container {

    padding: 13px;
}

.popup-title {

    font-family: Calibri, 'Trebuchet MS', sans-serif;
    font-size: 22px;
    color: #333;
    margin: 0px;
}

.popup-content-divider {

    margin: 0px;
    border-style: solid;
    border-width: 0.5px;
    border-color: #ddd;
}

.popup-container-body {

    background-color: #f0f0f0;
}


.popup-buttons-container {

    display: flex;
    justify-content: flex-start;
    padding: 13px;
}

.popup-buttons-container button {

    margin: 0px 5px 0px 10px;
    padding: 4px 15px;
    border-style: none;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    border-radius: 20px;
    background-color: #1DA0F7;
    color: white;
    font-size: 12pt;
}

.popup-buttons-container button:focus {
    outline: none;
}

.popup-buttons-container .secondary {

    margin-left: auto;
    background-color: #ddd;
    color: #2C363F; 
}

.popup-buttons-container .disabled {

    background-color: #ddd;
    color: #2C363F; 
    display: none;
}

.popup-buttons-container .add {

    margin: 0px 5px;
    padding: 4px 15px;
    background-color: #1DA0F7;
    color: #fff; 
}

.popup-input-line-container {

    margin: 15px 10px;
    width: 100%;
    display: grid;
    grid-template-columns: auto 51px;
}

.popup-input-line-container input {

    margin: 0px 0px;
    padding: 0px 7px;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13pt;
    color: #2C363F; 
    border-style: solid;
    border-width: 0.3px;
    border-color: #ddd;
}

.popup-input-line-container button {

    margin: 3px 20px 3px 7px;
    padding: 0px 7.25px 2px 7.25px;
    vertical-align: middle;
    border-style: none;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13pt;
    border-radius: 50%;
    background-color: #FF7272;
    color: white;
}

.popup-input-container {

    width: 100%;
    display: flex;
    padding: 10px 0px;
}

.popup-input-label {

    margin: 7px 5px 5px 15px;
    text-align: right;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13pt;
    color: #2C363F; 
}

.popup-input-field {

    -webkit-appearance: menulist-button;
    min-height: 27px;
    height: 27px;
    width: 100%;
    margin: 5px 15px 5px 5px;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13pt;
    color: #2C363F; 
}

.popup-input-checkbox-label {

    margin: 7px 5px 5px 15px;
    text-align: right;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13pt;
    color: #2C363F; 
}

.popup-input-checkbox {

    margin-top: 12px;
    margin-left: 25px;
    margin-right: 0px;
}
