.input-item-container {
    display: flex;
    flex-direction: row;
    min-height: min-content;
    justify-content: flex-end;
}

.chat-item {

    transform: scale(0);
    font-family: Arial, Helvetica, sans-serif;
    padding: 8px 13px;
    margin: 4px 10px;
    border-radius: 17px;
    transition: all 0.3s;
}

.rosie-measurement-item {

    display: flex;
    flex-direction: column;
    align-self: flex-start;
    background-color: #f0f0f0;
}

.rosie-measurement-item .measurement-title {

    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 18px;
    margin: 10px 0px 0px 15px;
}

.rosie-measurement-item .measurement-value {

    align-self: center;

    width: 87%;
    margin: 15px 10px;
    padding: 10px;

    font-size: 28px;
    text-align: center;

    background-color: #dbdbdb;

    outline: none;

    border-style: none;
    border-bottom-style: solid;
    border-bottom-color: #3f6985;
}

.invalid-input-text {
    
    margin: 0px 0px 10px 10px;
    color: #900;
    font-size: 10pt;
}

.button-holder {

    align-self: center;

    font-weight: bold;
    margin-bottom: 10px;
}

button {

    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: 16px;

    padding: 7px 12px;
    margin: 0px 5px;
    border-radius: 17px;

    background: #0099ff;
    border-style: none;

    cursor: pointer;
}

.disabled-button {

    background: #7bcaff;
    cursor: default;
}