.chat-item {

    transform: scale(0);
    font-family: Arial, Helvetica, sans-serif;
    padding: 8px 13px;
    margin: 4px 10px;
    border-radius: 17px;
    transition: all 0.3s;
}

.chat-item.animate {

    transform: scale(1);
}

.user-chat-item {

    align-self: flex-end;
    color: #fff;
    background-color: #0099ff;
}

.rosie-chat-item {

    align-self: flex-start;
    background-color: #f0f0f0;
}