.app-container {

    width: 100%;
    height: 100%;
    max-width: 600px;
    position: fixed;
    display: block;
    background-color: #fff;
}

.body-container {

    height: 100%;
    display: grid;
    grid-template-columns: 190px auto 500px;
}

.app-loading-container {
    
    display: grid;
    align-content: center;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #eee;
    opacity: 0.93;
}

.app-loading-container.hidden {

    display: none;
}

.app-loading-text {
    
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16pt;
    text-align: center;
}

.app-loading-loader {

    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    width: 50px;
}

.app-loading-loader.hidden {

    display: none;
}