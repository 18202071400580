.error-message-popup-label {

    margin: 12px 15px;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    font-size: 13pt;
    color: #2C363F; 
}

.error-message-popup-buttons-container {

    display: flex;
    justify-content: center;
    padding: 13px;
}

.error-message-popup-buttons-container button {

    margin: 0px 5px 0px 10px;
    padding: 4px 15px;
    border-style: none;
    font-family: Calibri, 'Trebuchet MS', sans-serif;
    border-radius: 20px;
    background-color: #1DA0F7;
    color: white;
    font-size: 12pt;
}