.simulator-container {

    height: calc(100vh - 100px);
    width: 100%;

    display: grid;
    grid-template-rows: auto minmax(100px, min-content);

    background-color: white;
    border-left-style: solid;
    border-left-color: #ddd;
    border-left-width: 2px;
}

.app-simulator-container {

    height: 100%;
    width: 100%;

    display: grid;
    grid-template-rows: 35px auto minmax(100px, min-content);

    background-color: white;
    border-left-style: solid;
    border-left-color: #ddd;
    border-left-width: 2px;
}

.chat-container {

    display: flex;
    flex-direction: column;

    padding-top: 10px;
    padding-bottom: 10px;

    background-color: #fcfcfc;
    overflow-y: scroll;
    overflow-x: hidden;
}

.response-container {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;

    background-color: #f0f0f0;

    border-top-style: solid;
    border-top-color: #eee;;
    border-top-width: 1px;
}

.chat-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background-color: #eee;
}

.chat-header-icon {
    height: 17px;
}

.chat-header-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10pt;
    color: #555;
    margin: 0;
    margin-left: 6px;
}