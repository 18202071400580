body {

    padding: 0px;
    margin: 0px;
    zoom: 1.2;
}

html, body {

    height: 100%;
    width: 100%;
    overflow: hidden;
}

.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #f9f9f9;
}

.unselectable {

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.disabled {

    opacity: 0.5;
}

.disabled:hover {

    background-color: #fff;
}

.hidden {

    display: none;
}

/* Scrollbar width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
    background: #eaeaea; 
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
    background: #ccc; 
}

/* Scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #999; 
}

@media screen and (max-width: 767px) {
    _::-webkit-full-page-media, _:future, :root .safari_only {
        padding-bottom: 0px;
    }
}