.chat-item {

    transform: scale(0);
    font-family: Arial, Helvetica, sans-serif;
    padding: 8px 13px;
    margin: 4px 10px;
    border-radius: 17px;
    transition: all 0.3s;
}

.rosie-video-item {

    display: flex;
    flex-direction: column;
    align-self: flex-start;
    min-height: min-content;
    background-color: #f0f0f0;
}

.rosie-video-item .timer-container {

    display: flex;
    justify-content: center;
    align-self: center;

    width: 300px;
    height: 150px;
    margin: 15px 10px;
    padding: 10px;

    font-size: 28px;
    text-align: center;

    background-color: #dbdbdb;

    border-style: none;
}

.timer-placeholder {

    align-self: center;
    width: 100px;
    height: 100px;
}

.rosie-video-item .video-title {

    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 18px;
    margin: 10px 0px 0px 15px;
}


.button-holder {

    align-self: center;

    font-weight: bold;
    margin-bottom: 10px;
}

button {

    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: 16px;

    padding: 7px 12px;
    margin: 0px 5px;
    border-radius: 17px;

    background: #0099ff;
    border-style: none;

    cursor: pointer;
}

.disabled-button {

    background: #7bcaff;
    cursor: default;
}